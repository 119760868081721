import React from 'react'

import Home from 'pages/home'
// import SEO from 'components/seo'

const IndexPage = () => (
    <Home />
)

export default IndexPage
